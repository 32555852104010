<style scoped lang='scss'>
.title{font-size: $Size30;}
.title-min{font-size: $Size18; color: $indexColor999;margin-left: 18px;padding-bottom: 3px;}
@keyframes jumpDiv
{
  0%,100% { transform:translateY(0px)}
  50% { transform:translateY(-10px)}
}

#products{
  padding-bottom: 100px;
  .jiantou{
    width: 114px;
    height: 37px;
    position: absolute;
    left: calc(50% - 57px);
    bottom: -37px;
    cursor: pointer;
    z-index: 8;
    animation:jumpDiv 1s ease-in-out infinite ;
  }
  .product-item{
    padding-top: 60px;
    position: relative;
  }
  .item-main{
    margin-top: 20px;
    background-color: #fff;
    padding: 70px 80px 80px 57px;

    .item-img{
      width: 507px;
      .item_BigImg{width: 507px;height: 507px;}
      .item_Min{
        width: 132px;height: 132px;margin:48px 7px 0;border: 1px solid #cccccc;
        cursor: pointer;
        .item_MinImg{width: 100%;}
      }
    }
    .item-right{
      width: 613px;
      margin-left: 142px;
      padding-top: 5px;
      position: relative;
      .item-title{
        font-size: 30px;
      }
      .line{ width: 100%;height: 2px;background-color: $footerColor666;margin: 20px 0 30px;}
      .item-title2{font-size: $Size24;margin-bottom: 12px;}
      .item-info{font-size: $Size16;margin-bottom: 42px;}
      .productIcons{
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
}


</style>

<template>
  <div id="products" ref="products">
    <div class="width" >
      <!-- 具体的内部遍历开始 -->
      <!-- 自主品牌的便利-->
      <div class="product-item games" ref="obm_games">
        <div class="acea-row row-left row-bottom">
          <div class="title">{{products.obm_games.name}}</div>
          <div class="title-min">
            {{products.obm_games.type==1?'':(products.obm_games.type==2?'嵌入式智慧产品解决方案':'智慧传感器通讯产品')}}
          </div>
        </div>
        <div class="item-main acea-row row-between" :ref="products.obm_games.list[0].productid">
          <div class="item-left">
            <div class="item-img">
              <img class="item_BigImg" :src="products.obm_games.list[0].productBigImg" alt="">
            </div>
            <div class="item-img acea-row row-center">
              <div class="item_Min" v-for="(item1, index1) in products.obm_games.list[0].productImg" :key="index1">
                <img class="item_MinImg" :src="item1" alt="" @mouseenter="agricultureChange('obm_games', 0, index1)">
              </div>
            </div>
          </div>
          <div class="item-right">
            <div class="item-title weight">{{products.obm_games.list[0].name}}</div>
            <div class="line"></div>
            <div v-if="products.obm_games.list[0].type==2">
              <div class="item-title2 weight">自主品牌</div>
              <div class="item-info">{{products.obm_games.list[0].obm}}</div>
            </div>
            <div v-else>
              <div class="item-title2 weight">客户</div>
              <div class="item-info">{{products.obm_games.list[0].customer}}</div>
            </div>
            <div>
              <div class="item-title2 weight">产品形态</div>
              <div class="item-info">{{products.obm_games.list[0].pattern}}</div>
            </div>
            <div>
              <div class="item-title2 weight">功能</div>
              <div class="item-info">{{products.obm_games.list[0].function}}</div>
            </div>
            <div>
              <div class="item-title2 weight">销售地</div>
              <div class="item-info">{{products.obm_games.list[0].tgy}}</div>
            </div>
            <div class="acea-row row-left productIcons">
              <img v-if="products.obm_games.list[0].ROHS" src="@assets/images/product/RoHs.jpg" alt="">
              <img v-if="products.obm_games.list[0].VCI" src="@assets/images/product/VCI.jpg" alt="">
              <img v-if="products.obm_games.list[0].UL" src="@assets/images/product/UL.jpg" alt="">
              <img v-if="products.obm_games.list[0].PAHS" src="@assets/images/product/PAHS.jpg" alt="">
              <img v-if="products.obm_games.list[0].UKCA" src="@assets/images/product/UKCA.jpg" alt="">
              <img v-if="products.obm_games.list[0].WEEE" src="@assets/images/product/WEEE.jpg" alt="">
              <img v-if="products.obm_games.list[0].FCC" src="@assets/images/product/FCC.jpg" alt="">
              <img v-if="products.obm_games.list[0].CE" src="@assets/images/product/CE.jpg" alt="">
              <img v-if="products.obm_games.list[0].BSMI" src="@assets/images/product/BSMI.jpg" alt="">
              <img v-if="products.obm_games.list[0].HDMI" src="@assets/images/product/HDMI.jpg" alt="">
            </div>
          </div>
        </div>
        <div v-show="obm_games" class="item-main acea-row row-between" v-for="(item, index) in products.obm_games.list.slice(1)" :key="index">
          <div class="item-left">
            <div class="item-img">
              <img class="item_BigImg" :src="item.productBigImg" alt="">
            </div>
            <div class="item-img acea-row row-center">
              <div class="item_Min" v-for="(item1, index1) in item.productImg" :key="index1">
                <img class="item_MinImg" :src="item1" alt="" @mouseenter="agricultureChange('obm_games',index+1,index1)">
              </div>
            </div>
          </div>
          <div class="item-right">
            <div class="item-title weight">{{item.name}}</div>
            <div class="line"></div>
            <div v-if="item.type==2">
              <div class="item-title2 weight">自主品牌</div>
              <div class="item-info">{{item.obm}}</div>
            </div>
            <div v-else>
              <div class="item-title2 weight">客户</div>
              <div class="item-info">{{item.customer}}</div>
            </div>
            <div>
              <div class="item-title2 weight">产品形态</div>
              <div class="item-info">{{item.pattern}}</div>
            </div>
            <div>
              <div class="item-title2 weight">功能</div>
              <div class="item-info">{{item.function}}</div>
            </div>
            <div>
              <div class="item-title2 weight">销售地</div>
              <div class="item-info">{{item.tgy}}</div>
            </div>

            <div class="acea-row row-left productIcons">
              <img v-if="item.ROHS" src="@assets/images/product/RoHs.jpg" alt="">
              <img v-if="item.VCI" src="@assets/images/product/VCI.jpg" alt="">
              <img v-if="item.UL" src="@assets/images/product/UL.jpg" alt="">
              <img v-if="item.PAHS" src="@assets/images/product/PAHS.jpg" alt="">
              <img v-if="item.UKCA" src="@assets/images/product/UKCA.jpg" alt="">
              <img v-if="item.WEEE" src="@assets/images/product/WEEE.jpg" alt="">
              <img v-if="item.FCC" src="@assets/images/product/FCC.jpg" alt="">
              <img v-if="item.CE" src="@assets/images/product/CE.jpg" alt="">
              <img v-if="item.BSMI" src="@assets/images/product/BSMI.jpg" alt="">
              <img v-if="item.HDMI" src="@assets/images/product/HDMI.jpg" alt="">
            </div>
          </div>
        </div>
        <!--  箭头 -->
        <img class="jiantou" v-if="products.obm_games.list.length>1" :src="games?upImg:downImg" alt="" @click="showMore('games')">
      </div>

      <!-- 游戏周边产品 -->
      <div class="product-item games_periphery" ref="games_periphery">
        <div class="acea-row row-left row-bottom">
          <div class="title">{{products.games_periphery.name}}</div>
          <div class="title-min">
            {{products.games_periphery.type==1?'':(products.games_periphery.type==2?'嵌入式智慧产品解决方案':'智慧传感器通讯产品')}}
          </div>
        </div>
        <div class="item-main acea-row row-between" :ref="products.games_periphery.list[0].productid">
          <div class="item-left">
            <div class="item-img">
              <img class="item_BigImg" :src="products.games_periphery.list[0].productBigImg" alt="">
            </div>
            <div class="item-img acea-row row-center">
              <div class="item_Min" v-for="(item1, index1) in products.games_periphery.list[0].productImg" :key="index1">
                <img class="item_MinImg" :src="item1" alt="" @mouseenter="agricultureChange('games_periphery',0,index1)">
              </div>
            </div>
          </div>
          <div class="item-right">
            <div class="item-title weight">{{products.games_periphery.list[0].name}}</div>
            <div class="line"></div>
            <div>
              <div class="item-title2 weight" v-if="products.games_periphery.list[0].customer">客户</div>
              <div class="item-info">{{products.games_periphery.list[0].customer}}</div>
            </div>
            <div>
              <div class="item-title2 weight" v-if="products.games_periphery.list[0].pattern">产品形态</div>
              <div class="item-info">{{products.games_periphery.list[0].pattern}}</div>
            </div>
            <div>
              <div class="item-title2 weight" v-if="products.games_periphery.list[0].function">功能</div>
              <div class="item-info">{{products.games_periphery.list[0].function}}</div>
            </div>
            <div>
              <div class="item-title2 weight" v-if="products.games_periphery.list[0].tgy">销售地</div>
              <div class="item-info">{{products.games_periphery.list[0].tgy}}</div>
            </div>
            <div class="acea-row row-left productIcons">
              <img v-if="products.games_periphery.list[0].ROHS" src="@assets/images/product/RoHs.jpg" alt="">
              <img v-if="products.games_periphery.list[0].VCI" src="@assets/images/product/VCI.jpg" alt="">
              <img v-if="products.games_periphery.list[0].UL" src="@assets/images/product/UL.jpg" alt="">
              <img v-if="products.games_periphery.list[0].PAHS" src="@assets/images/product/PAHS.jpg" alt="">
              <img v-if="products.games_periphery.list[0].UKCA" src="@assets/images/product/UKCA.jpg" alt="">
              <img v-if="products.games_periphery.list[0].WEEE" src="@assets/images/product/WEEE.jpg" alt="">
              <img v-if="products.games_periphery.list[0].FCC" src="@assets/images/product/FCC.jpg" alt="">
              <img v-if="products.games_periphery.list[0].CE" src="@assets/images/product/CE.jpg" alt="">
              <img v-if="products.games_periphery.list[0].BSMI" src="@assets/images/product/BSMI.jpg" alt="">
              <img v-if="products.games_periphery.list[0].HDMI" src="@assets/images/product/HDMI.jpg" alt="">
            </div>
          </div>
        </div>
        <div v-show="games_periphery" class="item-main acea-row row-between" v-for="(item, index) in products.games_periphery.list.slice(1)" :key="index">
          <div class="item-left">
            <div class="item-img">
              <img class="item_BigImg" :src="item.productBigImg" alt="">
            </div>
            <div class="item-img acea-row row-center">
              <div class="item_Min" v-for="(item1, index1) in item.productImg" :key="index1">
                <img class="item_MinImg" :src="item1" alt="" @mouseenter="agricultureChange('games_periphery',index+1,index1)">
              </div>
            </div>
          </div>
          <div class="item-right">
            <div class="item-title weight">{{item.name}}</div>
            <div class="line"></div>
            <div>
              <div class="item-title2 weight" v-if="item.customer">客户</div>
              <div class="item-info">{{item.customer}}</div>
            </div>
            <div>
              <div class="item-title2 weight" v-if="item.pattern">产品形态</div>
              <div class="item-info">{{item.pattern}}</div>
            </div>
            <div>
              <div class="item-title2 weight" v-if="item.function">功能</div>
              <div class="item-info">{{item.function}}</div>
            </div>
            <div>
              <div class="item-title2 weight" v-if="item.tgy">销售地</div>
              <div class="item-info">{{item.tgy}}</div>
            </div>
            <div class="acea-row row-left productIcons">
              <img v-if="item.ROHS" src="@assets/images/product/RoHs.jpg" alt="">
              <img v-if="item.VCI" src="@assets/images/product/VCI.jpg" alt="">
              <img v-if="item.UL" src="@assets/images/product/UL.jpg" alt="">
              <img v-if="item.PAHS" src="@assets/images/product/PAHS.jpg" alt="">
              <img v-if="item.UKCA" src="@assets/images/product/UKCA.jpg" alt="">
              <img v-if="item.WEEE" src="@assets/images/product/WEEE.jpg" alt="">
              <img v-if="item.FCC" src="@assets/images/product/FCC.jpg" alt="">
              <img v-if="item.CE" src="@assets/images/product/CE.jpg" alt="">
              <img v-if="item.BSMI" src="@assets/images/product/BSMI.jpg" alt="">
              <img v-if="item.HDMI" src="@assets/images/product/HDMI.jpg" alt="">
            </div>
          </div>
        </div>
        <!-- 箭头 -->
        <img class="jiantou" v-if="products.games_periphery.list.length>1" :src="games_periphery?upImg:downImg" alt="" @click="showMore('games_periphery')">
      </div>
      <!-- PCB板及电子元件产品 -->
      <div class="product-item ele_component" ref="ele_component">
        <div class="acea-row row-left row-bottom">
          <div class="title">{{products.ele_component.name}}</div>
          <div class="title-min">
            {{products.ele_component.type==1?'':(products.ele_component.type==2?'嵌入式智慧产品解决方案':'智慧传感器通讯产品')}}
          </div>
        </div>
        <div class="item-main acea-row row-between" :ref="products.ele_component.list[0].productid">
          <div class="item-left">
            <div class="item-img">
              <img class="item_BigImg" :src="products.ele_component.list[0].productBigImg" alt="">
            </div>
            <div class="item-img acea-row row-center">
              <div class="item_Min" v-for="(item1, index1) in products.ele_component.list[0].productImg" :key="index1">
                <img class="item_MinImg" :src="item1" alt="" @mouseenter="agricultureChange('ele_component', 0,index1)">
              </div>
            </div>
          </div>
          <div class="item-right">
            <div class="item-title weight">{{products.ele_component.list[0].name}}</div>
            <div class="line"></div>
            <div>
              <div class="item-title2 weight">客户</div>
              <div class="item-info">{{products.ele_component.list[0].customer}}</div>
            </div>
            <div>
              <div class="item-title2 weight">产品类型</div>
              <div class="item-info">{{products.ele_component.list[0].type}}</div>
            </div>
            <div>
              <div class="item-title2 weight">优势</div>
              <div class="item-info">{{products.ele_component.list[0].advantage}}</div>
            </div>
            <div class="acea-row row-left productIcons">
              <img v-if="products.ele_component.list[0].ROHS" src="@assets/images/product/RoHs.jpg" alt="">
              <img v-if="products.ele_component.list[0].VCI" src="@assets/images/product/VCI.jpg" alt="">
              <img v-if="products.ele_component.list[0].UL" src="@assets/images/product/UL.jpg" alt="">
              <img v-if="products.ele_component.list[0].PAHS" src="@assets/images/product/PAHS.jpg" alt="">
              <img v-if="products.ele_component.list[0].UKCA" src="@assets/images/product/UKCA.jpg" alt="">
              <img v-if="products.ele_component.list[0].WEEE" src="@assets/images/product/WEEE.jpg" alt="">
              <img v-if="products.ele_component.list[0].FCC" src="@assets/images/product/FCC.jpg" alt="">
              <img v-if="products.ele_component.list[0].CE" src="@assets/images/product/CE.jpg" alt="">
              <img v-if="products.ele_component.list[0].BSMI" src="@assets/images/product/BSMI.jpg" alt="">
              <img v-if="products.ele_component.list[0].HDMI" src="@assets/images/product/HDMI.jpg" alt="">
            </div>
          </div>
        </div>
        <div v-show="ele_component" class="item-main acea-row row-between" v-for="(item, index) in products.ele_component.list.slice(1)" :key="index">
          <div class="item-left">
            <div class="item-img">
              <img class="item_BigImg" :src="item.productBigImg" alt="">
            </div>
            <div class="item-img acea-row row-center">
              <div class="item_Min" v-for="(item1, index1) in item.productImg" :key="index1">
                <img class="item_MinImg" :src="item1" alt="" @mouseenter="agricultureChange('ele_component',index+1,index1)">
              </div>
            </div>
          </div>
          <div class="item-right">
            <div class="item-title weight">{{item.name}}</div>
            <div class="line"></div>
            <div>
              <div class="item-title2 weight">客户</div>
              <div class="item-info">{{item.customer}}</div>
            </div>
            <div>
              <div class="item-title2 weight">产品类型</div>
              <div class="item-info">{{item.type}}</div>
            </div>
            <div>
              <div class="item-title2 weight">优势</div>
              <div class="item-info">{{item.advantage}}</div>
            </div>
            <!-- after_sale售后服务 -->
            <div class="acea-row row-left productIcons">
              <img v-if="item.ROHS" src="@assets/images/product/RoHs.jpg" alt="">
              <img v-if="item.VCI" src="@assets/images/product/VCI.jpg" alt="">
              <img v-if="item.UL" src="@assets/images/product/UL.jpg" alt="">
              <img v-if="item.PAHS" src="@assets/images/product/PAHS.jpg" alt="">
              <img v-if="item.UKCA" src="@assets/images/product/UKCA.jpg" alt="">
              <img v-if="item.WEEE" src="@assets/images/product/WEEE.jpg" alt="">
              <img v-if="item.FCC" src="@assets/images/product/FCC.jpg" alt="">
              <img v-if="item.CE" src="@assets/images/product/CE.jpg" alt="">
              <img v-if="item.BSMI" src="@assets/images/product/BSMI.jpg" alt="">
              <img v-if="item.HDMI" src="@assets/images/product/HDMI.jpg" alt="">
            </div>
          </div>
        </div>
        <!-- 箭头 -->
        <img class="jiantou" v-if="products.ele_component.list.length>1" :src="ele_component?upImg:downImg" alt="" @click="showMore('ele_component')">
      </div>

      <!-- 智慧显示产品 -->
      <div class="product-item wisdom_display" ref="wisdom_display">
        <div class="acea-row row-left row-bottom">
          <div class="title">{{products.wisdom_display.name}}</div>
          <div class="title-min">
            {{products.wisdom_display.type==1?'':(products.wisdom_display.type==2?'嵌入式智慧产品解决方案':'智慧传感器通讯产品')}}
          </div>
        </div>
        <div class="item-main acea-row row-between" :ref="products.wisdom_display.list[0].productid">
          <div class="item-left">
            <div class="item-img">
              <img class="item_BigImg" :src="products.wisdom_display.list[0].productBigImg" alt="">
            </div>
            <div class="item-img acea-row row-center">
              <div class="item_Min" v-for="(item1, index1) in products.wisdom_display.list[0].productImg" :key="index1">
                <img class="item_MinImg" :src="item1" alt="" @mouseenter="agricultureChange('wisdom_display', 0,index1)">
              </div>
            </div>
          </div>
          <div class="item-right">
            <div class="item-title weight">{{products.wisdom_display.list[0].name}}</div>
            <div class="line"></div>
            <div>
              <div class="item-title2 weight">客户</div>
              <div class="item-info">{{products.wisdom_display.list[0].customer}}</div>
            </div>
            <div>
              <div class="item-title2 weight">产品形态</div>
              <div class="item-info">{{products.wisdom_display.list[0].pattern}}</div>
            </div>
            <div>
              <div class="item-title2 weight">功能</div>
              <div class="item-info">{{products.wisdom_display.list[0].function}}</div>
            </div>
            <div>
              <div class="item-title2 weight">销售地</div>
              <div class="item-info">{{products.wisdom_display.list[0].tgy}}</div>
            </div>
            <div class="acea-row row-left productIcons">
              <img v-if="products.wisdom_display.list[0].ROHS" src="@assets/images/product/RoHs.jpg" alt="">
              <img v-if="products.wisdom_display.list[0].VCI" src="@assets/images/product/VCI.jpg" alt="">
              <img v-if="products.wisdom_display.list[0].UL" src="@assets/images/product/UL.jpg" alt="">
              <img v-if="products.wisdom_display.list[0].PAHS" src="@assets/images/product/PAHS.jpg" alt="">
              <img v-if="products.wisdom_display.list[0].UKCA" src="@assets/images/product/UKCA.jpg" alt="">
              <img v-if="products.wisdom_display.list[0].WEEE" src="@assets/images/product/WEEE.jpg" alt="">
              <img v-if="products.wisdom_display.list[0].FCC" src="@assets/images/product/FCC.jpg" alt="">
              <img v-if="products.wisdom_display.list[0].CE" src="@assets/images/product/CE.jpg" alt="">
              <img v-if="products.wisdom_display.list[0].BSMI" src="@assets/images/product/BSMI.jpg" alt="">
              <img v-if="products.wisdom_display.list[0].HDMI" src="@assets/images/product/HDMI.jpg" alt="">
            </div>
          </div>
        </div>
        <div v-show="wisdom_display" class="item-main acea-row row-between" v-for="(item, index) in products.wisdom_display.list.slice(1)" :key="index">
          <div class="item-left">
            <div class="item-img">
              <img class="item_BigImg" :src="item.productBigImg" alt="">
            </div>
            <div class="item-img acea-row row-center">
              <div class="item_Min" v-for="(item1, index1) in item.productImg" :key="index1">
                <img class="item_MinImg" :src="item1" alt="" @mouseenter="agricultureChange('wisdom_display',index+1,index1)">
              </div>
            </div>
          </div>
          <div class="item-right">
            <div class="item-title weight">{{item.name}}</div>
            <div class="line"></div>
            <div>
              <div class="item-title2 weight">客户</div>
              <div class="item-info">{{item.customer}}</div>
            </div>
            <div>
              <div class="item-title2 weight">产品形态</div>
              <div class="item-info">{{item.pattern}}</div>
            </div>
            <div>
              <div class="item-title2 weight">功能</div>
              <div class="item-info">{{item.function}}</div>
            </div>
            <div>
              <div class="item-title2 weight">销售地</div>
              <div class="item-info">{{item.tgy}}</div>
            </div>
            <!-- after_sale售后服务 -->
            <div class="acea-row row-left productIcons">
              <img v-if="item.ROHS" src="@assets/images/product/RoHs.jpg" alt="">
              <img v-if="item.VCI" src="@assets/images/product/VCI.jpg" alt="">
              <img v-if="item.UL" src="@assets/images/product/UL.jpg" alt="">
              <img v-if="item.PAHS" src="@assets/images/product/PAHS.jpg" alt="">
              <img v-if="item.UKCA" src="@assets/images/product/UKCA.jpg" alt="">
              <img v-if="item.WEEE" src="@assets/images/product/WEEE.jpg" alt="">
              <img v-if="item.FCC" src="@assets/images/product/FCC.jpg" alt="">
              <img v-if="item.CE" src="@assets/images/product/CE.jpg" alt="">
              <img v-if="item.BSMI" src="@assets/images/product/BSMI.jpg" alt="">
              <img v-if="item.HDMI" src="@assets/images/product/HDMI.jpg" alt="">
            </div>
          </div>
        </div>
        <!-- 箭头 -->
        <img class="jiantou" v-if="products.wisdom_display.list.length>1" :src="wisdom_display?upImg:downImg" alt="" @click="showMore('wisdom_display')">
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'products',
  components: {
  },
  data () {
    return {
      obm_games: true,
      games_periphery: true,
      wisdom_display: true,
      ele_component: true,
      upImg: require('../assets/images/product/up.png'),
      downImg: require('../assets/images/product/down.png'),
      // 获取的商品要按事件排序，某个品类下最新的排在最前面
      products:{
        // 游戏机
        obm_games:{
          name:'自主品牌游戏产品',
          type: 1, // 1 表示自主品牌 2 嵌入式智慧产品解决方案 3 表示“智慧传感器通讯产品”
          list: [
            {
              productid: 'p10000',// 产品id
              sort: 1,
              type:2,//1表示客户 2表示自主品牌产品
              productBigImg: require('../assets/images/product/obm/obm_jcyg01.jpg'),
              // 产品图片
              productImg:[
                require('../assets/images/product/obm/obm_jcyg01.jpg'),
                require('../assets/images/product/obm/obm_jcyg02.jpg'),
                require('../assets/images/product/obm/obm_jcyg03.jpg'),
              ],
              name: '自主品牌机车摇杆手柄',// 产品名称
              obm: '自主研发、自主生产、自主销售，得到全球知名游戏巨头的正牌授权',// 客户
              pattern: '游戏机外接设备',// 产品形态
              function: '连到指定游戏机上游玩机车游戏',// 功能
              tgy: '日本',// 销售地
              ROHS: true,
              VCI: true,
              UL: false,
              HDMI: true,
              PAHS:false,
              UKCA:false,
              WEEE:false,
              FCC:false,
              CE:false,
              BSMI:false,
            },
          ]
        },
        // 游戏周边产品
        games_periphery:{
          name:'游戏周边产品解决方案',
          type: 2,// 1 表示自主品牌 2 嵌入式智慧产品解决方案 3 表示“智慧传感器通讯产品”
          list: [
            {
              productid: 'p10001',// 产品id
              sort: 1,
              productBigImg: require('../assets/images/product/games_periphery/jcyg1.jpg'),
              // 产品图片
              productImg:[
                require('../assets/images/product/games_periphery/jcyg1.jpg'),
                require('../assets/images/product/games_periphery/jcyg2.jpg'),
                require('../assets/images/product/games_periphery/jcyg3.jpg'),
              ],
              name: '即插即玩摇杆电车',// 产品名称
              customer: '世界知名游戏巨头',// 客户
              pattern: '模拟地铁操纵室的驾驶台，使用HDMI线连接到显示器上，游玩驾驶地铁的游戏',// 产品形态
              function: '刹车、油门、信号灯及方向转变',// 功能
              tgy: '日本',// 销售地
              ROHS: true,
              VCI: true,
              UL: false,
              HDMI: true,
              PAHS:false,
              UKCA:false,
              WEEE:false,
              FCC:false,
              CE:false,
              BSMI:false,
            },
            {
              productid: 'p10002',// 产品id
              sort: 2,
              productBigImg: require('../assets/images/product/games_periphery/fgjj1.jpg'),
              // 产品图片
              productImg:[
                require('../assets/images/product/games_periphery/fgjj1.jpg'),
                require('../assets/images/product/games_periphery/fgjj2.jpg'),
                require('../assets/images/product/games_periphery/fgjj3.jpg'),
              ],
              name: '80年代经典复古街机',// 产品名称
              customer: '日本知名游戏厂商',// 客户
              pattern: '摆在桌上游玩的小型游戏机',// 产品形态
              function: '可使用自带LCD屏游玩多款80年代经典游戏，内含HDMI高清数据线和接口，可连接电视，显示屏，投影仪等;无损传递，多大的尺寸都能清淅显示',// 功能
              tgy: '中国、日本、台湾、美国、欧洲等全球各地',// 销售地
              ROHS: true,
              VCI: false,
              UL: false,
              HDMI: true,
              PAHS:false,
              UKCA:false,
              WEEE:false,
              FCC:true,
              CE:true,
              BSMI:true,
            },
            {
              productid: 'p10003',// 产品id
              sort: 3,
              productBigImg: require('../assets/images/product/games_periphery/blyxj1.jpg'),
              // 产品图片
              productImg:[
                require('../assets/images/product/games_periphery/blyxj1.jpg'),
                require('../assets/images/product/games_periphery/blyxj2.jpg'),
                require('../assets/images/product/games_periphery/blyxj3.jpg'),
              ],
              name: '旋转屏复古游戏机',// 产品名称
              customer: '世界知名游戏巨头',// 客户
              pattern: '复古mini游戏街机',// 产品形态
              function: '支持横竖两种可旋转屏幕来体验不同种类的游戏，可插入SD卡添加游戏。内含HDMI高清数据线和接口，可连接电视，显示屏，投影仪等;  无损传递，多大的尺寸都能清淅显示',// 功能
              tgy: '中国、日本、美国、欧洲等全球各地',// 销售地
              ROHS: true,
              VCI: false,
              UL: false,
              HDMI: true,
              PAHS:false,
              UKCA:false,
              WEEE:true,
              FCC:true,
              CE:true,
              BSMI:false,
            },
            {
              productid: 'p10004',// 产品id
              sort: 4,
              productBigImg: require('../assets/images/product/games_periphery/fgyxsb1.jpg'),
              // 产品图片
              productImg:[
                require('../assets/images/product/games_periphery/fgyxsb1.jpg'),
                require('../assets/images/product/games_periphery/fgyxsb2.jpg'),
                require('../assets/images/product/games_periphery/fgyxsb3.jpg'),
              ],
              name: '复古游戏手柄',// 产品名称
              customer: '世界知名游戏巨头',// 客户
              pattern: '复古游戏手柄',// 产品形态
              function: '八方向十字按键，两米线长方便连接游戏机在电视上体验游戏',// 功能
              tgy: '中国、日本、台湾、美国、欧洲等全球各地',// 销售地
              ROHS: true,
              VCCI: false,
              UL: false,
              HDMI: false,
              PAHS:false,
              UKCA:false,
              WEEE:true,
              FCC:true,
              CE:true,
              BSMI:false,
            },
            {
              productid: 'p10005',// 产品id
              sort: 5,
              productBigImg: require('../assets/images/product/games_periphery/blyxsb1.jpg'),
              // 产品图片
              productImg:[
                require('../assets/images/product/games_periphery/blyxsb1.jpg'),
                require('../assets/images/product/games_periphery/blyxsb2.jpg'),
                require('../assets/images/product/games_periphery/blyxsb3.jpg'),
              ],
              name: '复古游戏手柄',// 产品名称
              customer: '世界知名游戏巨头',// 客户
              pattern: '复古游戏手柄',// 产品形态
              function: '八方向十字按键，两米线长方便连接游戏机在电视上体验游戏',// 功能
              tgy: '中国、日本、美国、欧洲等全球各地',// 销售地
              ROHS: true,
              VCCI: false,
              UL: false,
              HDMI: false,
              PAHS:false,
              UKCA:false,
              WEEE:true,
              FCC:true,
              CE:true,
              BSMI:false,
            }
          ]
        },
        // 智慧显示产品
        wisdom_display:{
          name:'SoC解决方案',
          type: 2, // 1 表示自主品牌 2 嵌入式智慧产品解决方案 3 表示“智慧传感器通讯产品”
          list: [
            {
              productid: 'p10006',// 产品id
              sort: 1,
              productBigImg: require('../assets/images/product/Intelligent/Intelligent1.jpg'),
              // 产品图片
              productImg:[
                require('../assets/images/product/Intelligent/Intelligent1.jpg'),
                require('../assets/images/product/Intelligent/Intelligent2.jpg'),
                require('../assets/images/product/Intelligent/Intelligent3.jpg'),
              ],
              name: '智慧灯罩广告牌',// 产品名称
              customer: '日本知名电子产品制造商',// 客户
              pattern: '灯罩上带4K显示屏',// 产品形态
              function: '用于酒店、商店、餐厅的广告展示。最高支持4K输出，支持主流视频编码格式，可接收并播放云端的实时视频流',// 功能
              tgy: '日本',// 销售地
              ROHS: false,
              VCI: false,
              UL: false,
              HDMI: false,
              PAHS:false,
              UKCA:false,
              WEEE:false,
              FCC:false,
              CE:false,
              BSMI:false,
            }
          ]
        },
        // PCB板及电子元件
        ele_component:{
          name:'PCB板及电子元件',
          type: 2, // 1 表示自主品牌 2 嵌入式智慧产品解决方案 3 表示“智慧传感器通讯产品”
          list: [
            {
              productid: 'dz10000',// 产品id
              sort: 1,
              productBigImg: require('../assets/images/product/ele_component/pcba1.jpg'),
              // 产品图片
              productImg:[
                require('../assets/images/product/ele_component/pcba1.jpg'),
                require('../assets/images/product/ele_component/pcba2.jpg'),
                require('../assets/images/product/ele_component/pcba3.jpg'),
              ],
              name: 'PCBA 制板',// 产品名称
              customer: '知名消费电子品牌',// 客户
              pattern: '',// 产品形态
              function: '',// 功能
              type:'单层、双层、4层以上多层及HDI PCB板',
              advantage:'PCB方案设计、制造、电子装联、组装和测试等一站式的解决方案。拥有中高端的产品结构、专业的产品开发及制造技术、稳定的质量表现与完善的管理体系，与多家知名企业建立稳定的合作关系',
              tgy: '',// 销售地
              ROHS: false,
              VCI: false,
              UL: false,
              HDMI: false,
              PAHS:false,
              UKCA:false,
              WEEE:false,
              FCC:false,
              CE:false,
              BSMI:false,
            },
            {
              productid: 'dz10001',// 产品id
              sort: 2,
              productBigImg: require('../assets/images/product/ele_component/dzwl.jpg'),
              // 产品图片
              productImg:[
                require('../assets/images/product/ele_component/dzwl.jpg'),
              ],
              name: '电子物料',// 产品名称
              customer: '中国、日本等',// 客户
              pattern: '',// 产品形态
              function: '',// 功能
              type:'处理器、晶振、电容、电阻、IC、传感器等',
              advantage:'与国内外多家知名硬件公司有多年稳定的合作关系，全志国内代理授权。',
              tgy: '',// 销售地
              ROHS: false,
              VCI: false,
              UL: false,
              HDMI: false,
              PAHS:false,
              UKCA:false,
              WEEE:false,
              FCC:false,
              CE:false,
              BSMI:false,
            }
          ]
        }
      },
      type: '',
      sort: '',
    }
  },
  computed: {},
  created () {
    this.type = this.$route.query.type || '';
    this.sort = this.$route.query.sort || '';
    if(this.$route.query.type){
      this[this.type] = true;
    }
  },
  mounted(){
    window.scrollTo({top: 0,left: 0});
    new this.$WOW.WOW().init();
    let _that = this;
    if(_that.type){
      let type = _that.type;
      let offetTop = _that.$refs[type].offsetTop;
      // console.log('产品类目的偏移量'+offetTop);
      // console.log(_that.sort);
      if(_that.sort){
        if(_that.sort == 1){
          window.scrollTo({top: offetTop+0,left: 0,behavior: 'smooth'});
        }else{
          let index = _that.sort;
          let vNode = (document.getElementsByClassName(type)[0]);
          let newNode = vNode.getElementsByClassName('item-main')[index-1];
          let top = newNode.offsetTop + offetTop + 0;
          setTimeout(function(){
            window.scrollTo({top: top,left: 0,behavior: 'smooth'});
          },100)
        }

      }else{
        window.scrollTo({top: offetTop+0,left: 0,behavior: 'smooth'});
      }

    }else{
      window.scrollTo({top: 0,left: 0,behavior: 'smooth'});
    }
  },
  methods: {
    agricultureChange(str, index, num){
      this.products[str].list[index].productBigImg = this.products[str].list[index].productImg[num]
    },
    showMore(str){
      this[str] = !this[str];
    }
  },

}

</script>
